<template>
  <div>
    <v-card flat>
      <v-card-text>
        <v-toolbar flat>
          <v-dialog
            ref="dialog"
            v-model="weakmodel"
            color="#f4813f"
            :return-value.sync="weakdate"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedDateFormattedweek"
                dense
                outlined
                class="mt-6 ml-2"
                persistent-hint
                readonly
                label="Filter by Date"
                color="#f4813f"
                append-icon="mdi-calendar"
                style="max-width: 150px"
                @click:append="weakdate ? getweekcal() : getweekcal()"
                v-bind="attrs"
                v-on="on"
              >
              </v-text-field>
            </template>
            <v-date-picker
              :max="
                new Date(new Date().setDate(new Date().getDate() - 1))
                  .toISOString()
                  .substr(0, 10)
              "
              :min="minmonth"
              v-model="weakdate"
              color="#f4813f"
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text color="#f4813f" @click="weakmodel = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="#f4813f"
                v-on:click="get_weaklist2()"
                @click="$refs.dialog.save(weakdate)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-toolbar>
        <v-data-table
          :headers="SwipeDataHeaders"
          :items="SwipeDataItems"
          dense
          :height="height"
          class="overflow-y-auto overflow"
          :search="search"
          :fixed-header="fixedHeader"
          :loading="isLoading"
          loading-text="Loading... Please wait"
          :options.sync="pagination"
          @update:options="getweek_list()"
          :footer-props="{
            'items-per-page-options': [10, 15, 20, 25],
          }"
          :sort-by="['user_name']"
        >
          <template v-slot:no-results>
            <v-alert :value="true" class="gradient-bg2 white--text">
              No records found!
            </v-alert>
          </template>
          <template
            v-for="col in days_in_current_month"
            v-slot:[`item.day${col+1}`]="{ item }"
          >
            <WeekdataChip :key="col" :item="item.monthly[col]" />
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import moment from "moment";
import WeekdataChip from "@/views/ChildHomePages/Reports/WeeklyReports/WeekdataChip.vue";
import { API, graphqlOperation } from "aws-amplify";
import { get_weekly_report_v2 } from "@/graphql/queries.js";
export default {
  components: {
    WeekdataChip,
  },
  data() {
    return {
      weakdate: new Date(new Date().setDate(new Date().getDate() - 1))
        .toISOString()
        .substr(0, 10),
      weakmodel: false,
      SwipeDataHeaders: [],
      SwipeDataItems: [],
      days_in_current_month: [],
      datearray: [],
      height: 0,
      next_token: null,
      fixedHeader: true,
      isLoading: false,
      search: "",
      minmonth: "",
      selectedDate: "",
      pagination: {
        rowsPerPage: 32,
        page: 1,
      },
    };
  },
  computed: {
    computedDateFormattedweek() {
      return this.formatDate(this.weakdate);
    },
  },
  async created() {
    this.height = window.innerHeight - 220;
    this.selectedDate = this.get_orgdate(
      this.$store.getters.get_org_details[0].organisation_created_on
    );

    this.minmonth = moment(this.selectedDate, "MM/DD/YYYY").format(
      "YYYY-MM-DD"
    );
    await this.getweek_list();
    await this.get_all_days_of_month();
  },
  methods: {
    get_orgdate(date) {
      let a = new Date(date * 1000).toLocaleTimeString("en-US", {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
      return a.toUpperCase();
    },
    async get_weaklist2() {
      this.SwipeDataItems = [];
      await this.getweek_list();
      await this.get_all_days_of_month();
    },
    getweekcal() {
      this.weakmodel = true;
    },
    formatDate(weakdate) {
      if (!weakdate) return null;
      const [year, month, day] = weakdate.split("-");
      return `${day}/${month}/${year}`;
    },
    get_all_days_of_month() {
      this.SwipeDataHeaders = [];
      this.SwipeDataHeaders = [
        {
          text: "Member Id",
          value: "employee_id",
          sortable: false,
          width: "auto",
        },
        {
          text: "Member Name",
          value: "user_name",
          sortable: false,
          width: "auto",
        },
        // {
        //   text: "Location Name",
        //   value: "location_name",
        //   sortable: false,
        //   width: "auto",
        // },

        {
          text: "Σ Duration",
          value: "total_minutes",
          sortable: false,
          width: "auto",
        },
        {
          text: "P",
          value: "no_of_present_days",
          sortable: false,
          width: "auto",
        },
        {
          text: "L",
          value: "no_of_leave_days",
          sortable: false,
          width: "auto",
        },
        {
          text: "A",
          value: "no_of_absent_days",
          sortable: false,
          width: "auto",
        },
      ];

      this.days_in_current_month = Array.from(
        Array(this.datearray.length).keys()
      );
      //       this.days_in_current_month = []
      // for(let k=0;k<this.datearray.length;k++){
      //   this.days_in_current_month.push(k)
      // }

      // for (let i = 1; i <= 7; i++) {
      //   this.SwipeDataHeaders.push({
      //     text: i,
      //     value: `day${i}`,
      //     sortable: false,
      //     width: "auto",
      //   });
      // }
      // this.days_in_current_month = this.datearray
      //   .slice(0, 7)
      //   .map((item) => parseInt(item.date));

      // console.log(this.days_in_current_month);

      // // Add headers for the first 7 days to SwipeDataHeaders
      for (let i = 0; i < this.datearray.length; i++) {
        this.SwipeDataHeaders.push({
          text: this.datearray[i].date, // Use the name from the date object
          value: `day${i + 1}`,
          sortable: false,
          width: "auto",
        });
      }
    },
    async getweek_list() {
      this.checkoverlay = 1;
      this.overlay = true;
      this.isLoading = true;
      try {
        await API.graphql(
          graphqlOperation(get_weekly_report_v2, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
              nextToken: this.next_token,
              workshift_id: "ALL",
              limit: 32,
              user_name: "",
              location_id: "ALL",
              dynamo_date: this.weakdate.slice(0, 10),
            },
          })
        ).then((res) => {
          var weakreport = JSON.parse(res.data.get_weekly_report_v2);
          // console.log(JSON.parse(res.data.get_weekly_report_v2));
          var response = weakreport.data;
          let array = this.SwipeDataItems.concat(response);
          this.SwipeDataItems = array;
          const uniqueArrayOfObjects = this.SwipeDataItems.filter(
            (obj, index, self) =>
              index === self.findIndex((o) => o.user_id === obj.user_id)
          );
          this.SwipeDataItems = uniqueArrayOfObjects;
          this.next_token = weakreport.nextToken;
          // console.log(this.next_token, "next_token");
          this.datearray = this.SwipeDataItems[0].monthly;

          this.overlay = false;
          this.isLoading = false;
        });
      } catch (err) {
        console.log(err);
        this.$store.commit("snackbar/showMessage", {
          content: err.errors[0].message,
          color: "red",
        });
        this.SwipeDataItems = [];
        this.checkoverlay = 0;
        this.overlay = false;
        this.isLoading = false;
      }
    },
  },
};
</script>

<style>
</style>